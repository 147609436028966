import { useState, useEffect } from 'react';
import axios from 'axios';
import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../css/Addacgroup.css';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useToken } from '../components/auth';
import moment from 'moment';
import writeXlsxFile from 'write-excel-file';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useUser } from '../components/auth';
// import SingleDriverRation from '../components/ReRation';
// import DriversRation from '../components/ReRationDriveres';
// import { Link } from 'react-router-dom';

export default function ReportOwner() {
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const user = useUser();
  const { owner_id } = user;
  const [searchMessage, setSearchMessage] = useState('');
  //const [message, setMessage] = useState('');

  // Getting existing users
  const [tDatas, setTDatas] = useState([]);
  useEffect(() => {
    const userData = async () => {
      setSearchMessage('Tegereza.....');
      document.querySelector('#searchMessage').style.color = 'blue';
      const time = moment().format().substring(0, 10);
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/report/general-owner?sdate=${time}&edate=${time}&owner=${owner_id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setTDatas(data);
        if (data.regeneral.length !== 0) {
          setSearchMessage('');
        } else {
          setSearchMessage(`No data found for today`);
          document.querySelector('#searchMessage').style.color = 'green';
        }
      } catch (error) {
        console.log(error);
        setSearchMessage('Error while retrieving data');
        document.querySelector('#searchMessage').style.color = 'red';
      }
    };
    userData();
  }, [token, url, owner_id]);

  async function search() {
    setTDatas([]);
    const sdate = document.querySelector('#s-date').value;
    const edate = document.querySelector('#e-date').value;
    if (sdate === '' || edate === '') {
      setSearchMessage('Please select start date and end date');
      document.querySelector('#searchMessage').style.color = 'red';
    } else {
      setSearchMessage('Tegereza.....');
      document.querySelector('#searchMessage').style.color = 'blue';

      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/report/general-owner?sdate=${sdate}&edate=${edate}&owner=${owner_id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setTDatas(data);
        if (data.regeneral.length !== 0) {
          setSearchMessage('');
        } else {
          setSearchMessage(`No data found from ${sdate} to ${edate}`);
          document.querySelector('#searchMessage').style.color = 'green';
        }
      } catch (error) {
        console.log(error);
        setSearchMessage('Error while retrieving data');
        document.querySelector('#searchMessage').style.color = 'red';
      }
    }
  }

  //preparing data for display
  let total = 0;
  let ac5 = 0;
  let kivu_belt_to = 0;
  let ration = 0;
  let jali_muhanga = 0;
  let mukamira = 0;
  let jali_rubavu = 0;
  let jali_karongi = 0;
  let jali_rusizi = 0;
  let jali_ngororero = 0;
  let jali_kabaya = 0;
  let atp_mahoko = 0;
  let kvcs = 0;
  let kigali = 0;
  let muhanga = 0;
  let ngororero = 0;
  let mukamirah = 0;
  let rubavu = 0;
  let karongi = 0;
  let guhanagura = 0;
  let gutega = 0;
  let pneu = 0;
  let sgarage = 0;
  let garage = 0;
  let ogarage = 0;
  let mazout = 0;
  let ikinamba = 0;
  let sp = 0;
  let hashi = 0;
  let merez = 0;
  let restTotal = 0;
  let displayData = [];
  let ownerCars = [];

  if (Object.keys(tDatas).length !== 0) {
    tDatas.regeneral.forEach((row) => {
      if (row.owner === owner_id) {
        ownerCars.push(row);
      }
    });

    //console.log(ownerCars);

    let i = 0;
    displayData = ownerCars.map((row) => {
      const amount = row.amount ? row.amount : 0;
      const kivu_belt = row.kivu_belt ? row.kivu_belt : 0;
      const ac = (amount * 4.5) / 100;
      i++;
      total += parseInt(amount);
      ac5 += ac;
      kivu_belt_to += parseInt(kivu_belt);
      ration += row.ration;
      jali_muhanga += row.jali_muhanga;
      mukamira += row.mukamira;
      jali_rubavu += row.jali_rubavu;
      jali_karongi += row.jali_karongi;
      jali_rusizi += row.jali_rusizi;
      jali_ngororero += row.jali_ngororero;
      jali_kabaya += row.jali_kabaya;
      atp_mahoko += row.atp_mahoko;
      kvcs += row.kvcs;
      kigali += row.kigali;
      muhanga += row.muhanga;
      ngororero += row.ngororero;
      mukamirah += row.mukamirah;
      rubavu += row.rubavu;
      karongi += row.karongi;
      guhanagura += row.guhanagura;
      gutega += row.gutega;
      pneu += row.pneu;
      sgarage += row.sgarage;
      garage += row.garage;
      ogarage += row.ogarage;
      mazout += row.mazout;
      ikinamba += row.ikinamba;
      //console.log(typeof row.sp);
      const ss = Number(row.sp);
      const hh = Number(row.hashi);
      const mm = Number(row.merez);
      sp += ss;
      hashi += hh;
      merez += mm;
      const rest =
        amount -
        (ac +
          parseInt(kivu_belt) +
          row.ration +
          row.jali_muhanga +
          row.mukamira +
          row.jali_rubavu +
          row.jali_karongi +
          row.jali_rusizi +
          row.jali_ngororero +
          row.jali_kabaya +
          row.atp_mahoko +
          row.kvcs +
          row.kigali +
          row.muhanga +
          row.ngororero +
          row.mukamirah +
          row.rubavu +
          row.karongi +
          row.guhanagura +
          row.gutega +
          row.sgarage +
          row.pneu +
          row.garage +
          row.mazout +
          Number(row.ikinamba) +
          row.ogarage +
          Number(row.sp) +
          Number(row.hashi) +
          Number(row.merez));
      restTotal += rest;
      //console.log(typeof restTotal);
      return { ...row, amount, kivu_belt, ac, rest, i };
    });
  }
  // preparing data for export

  const HEADER_ROW = [
    {
      value: 'No',
      fontWeight: 'bold',
    },
    {
      value: 'Plate',
      fontWeight: 'bold',
    },
    {
      value: 'Total',
      fontWeight: 'bold',
    },
    {
      value: '4.5% AC Group',
      fontWeight: 'bold',
    },
    {
      value: 'Parking',
      fontWeight: 'bold',
    },
    {
      value: 'Ration',
      fontWeight: 'bold',
    },
    {
      value: 'Jali Muhanga',
      fontWeight: 'bold',
    },
    {
      value: 'Mukamira',
      fontWeight: 'bold',
    },
    {
      value: 'Jali Rubavu',
      fontWeight: 'bold',
    },
    {
      value: 'Jali Karongi',
      fontWeight: 'bold',
    },
    {
      value: 'Jali Rusizi',
      fontWeight: 'bold',
    },
    {
      value: 'Jali Ngororero',
      fontWeight: 'bold',
    },
    {
      value: 'Jali Kabaya',
      fontWeight: 'bold',
    },
    {
      value: 'ATP Mahoko',
      fontWeight: 'bold',
    },
    {
      value: 'KVS',
      fontWeight: 'bold',
    },
    {
      value: 'KIGALI',
      fontWeight: 'bold',
    },
    {
      value: 'MUHANGA',
      fontWeight: 'bold',
    },
    {
      value: 'NGORORERO',
      fontWeight: 'bold',
    },
    {
      value: 'MUKAMIRA',
      fontWeight: 'bold',
    },
    {
      value: 'RUBAVU',
      fontWeight: 'bold',
    },
    {
      value: 'KARONGI 1',
      fontWeight: 'bold',
    },
    {
      value: 'GUHANAGURA',
      fontWeight: 'bold',
    },
    {
      value: 'GUTEGERA Abagenzi',
      fontWeight: 'bold',
    },
    {
      value: 'PNEU',
      fontWeight: 'bold',
    },
    {
      value: 'Garage',
      fontWeight: 'bold',
    },
    {
      value: 'Garage(Big)',
      fontWeight: 'bold',
    },
    {
      value: 'Garage - Owner',
      fontWeight: 'bold',
    },
    {
      value: 'Mazout',
      fontWeight: 'bold',
    },
    {
      value: 'Ikinamaba',
      fontWeight: 'bold',
    },
    {
      value: 'SP',
      fontWeight: 'bold',
    },
    {
      value: 'HASS',
      fontWeight: 'bold',
    },
    {
      value: 'MEREZ',
      fontWeight: 'bold',
    },
    {
      value: 'RESTE',
      fontWeight: 'bold',
    },
  ];
  //datas
  let rowss = [];
  rowss = displayData.map((row) => {
    return [
      {
        type: Number,
        value: row.i,
      },
      {
        value: row.plate,
      },
      {
        value: row.amount,
      },
      {
        value: row.ac,
      },
      {
        value: row.kivu_belt,
      },
      {
        value: row.ration,
      },
      {
        value: row.jali_muhanga,
      },
      {
        value: row.mukamira,
      },
      {
        value: row.jali_rubavu,
      },
      {
        value: row.jali_karongi,
      },
      {
        value: row.jali_rusizi,
      },
      {
        value: row.jali_ngororero,
      },
      {
        value: row.jali_kabaya,
      },
      {
        value: row.atp_mahoko,
      },
      {
        value: row.kvcs,
      },
      {
        value: row.kigali,
      },
      {
        value: row.muhanga,
      },
      {
        value: row.ngororero,
      },
      {
        value: row.mukamirah,
      },
      {
        value: row.rubavu,
      },
      {
        value: row.karongi,
      },
      {
        value: row.guhanagura,
      },
      {
        value: row.gutega,
      },
      {
        value: row.pneu,
      },
      {
        value: row.sgarage,
      },
      {
        value: row.garage,
      },
      {
        value: row.ogarage,
      },
      {
        value: row.mazout,
      },
      {
        value: row.ikinamba,
      },
      {
        value: row.sp,
      },
      {
        value: row.hashi,
      },
      {
        value: row.merez,
      },
      {
        value: row.rest,
      },
    ];
  });
  //totals
  const TOTAL_ROW = [
    {
      value: '',
      fontWeight: 'bold',
    },
    {
      value: 'Total',
      fontWeight: 'bold',
    },
    {
      value: total,
      fontWeight: 'bold',
    },
    {
      value: ac5,
      fontWeight: 'bold',
    },
    {
      value: kivu_belt_to,
      fontWeight: 'bold',
    },
    {
      value: ration,
      fontWeight: 'bold',
    },
    {
      value: jali_muhanga,
      fontWeight: 'bold',
    },
    {
      value: mukamira,
      fontWeight: 'bold',
    },
    {
      value: jali_rubavu,
      fontWeight: 'bold',
    },
    {
      value: jali_karongi,
      fontWeight: 'bold',
    },
    {
      value: jali_rusizi,
      fontWeight: 'bold',
    },
    {
      value: jali_ngororero,
      fontWeight: 'bold',
    },
    {
      value: jali_kabaya,
      fontWeight: 'bold',
    },
    {
      value: atp_mahoko,
      fontWeight: 'bold',
    },
    {
      value: kvcs,
      fontWeight: 'bold',
    },
    {
      value: kigali,
      fontWeight: 'bold',
    },
    {
      value: muhanga,
      fontWeight: 'bold',
    },
    {
      value: ngororero,
      fontWeight: 'bold',
    },
    {
      value: mukamirah,
      fontWeight: 'bold',
    },
    {
      value: rubavu,
      fontWeight: 'bold',
    },
    {
      value: karongi,
      fontWeight: 'bold',
    },
    {
      value: guhanagura,
      fontWeight: 'bold',
    },
    {
      value: gutega,
      fontWeight: 'bold',
    },
    {
      value: pneu,
      fontWeight: 'bold',
    },
    {
      value: sgarage,
      fontWeight: 'bold',
    },
    {
      value: garage,
      fontWeight: 'bold',
    },
    {
      value: ogarage,
      fontWeight: 'bold',
    },
    {
      value: mazout,
      fontWeight: 'bold',
    },
    {
      value: ikinamba,
      fontWeight: 'bold',
    },
    {
      value: sp,
      fontWeight: 'bold',
    },
    {
      value: hashi,
      fontWeight: 'bold',
    },
    {
      value: merez,
      fontWeight: 'bold',
    },
    {
      value: restTotal,
      fontWeight: 'bold',
    },
  ];

  const data = [HEADER_ROW, ...rowss, TOTAL_ROW];
  async function exportData(d) {
    await writeXlsxFile(d, {
      fileName: 'General-report.xlsx',
    });
  }
  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="Reports" />
        </div>
        {/* Tab here */}
        <br />

        <Tabs
          defaultActiveKey="reports"
          id="uncontrolled-tab-example"
          className="mb-2"
        >
          <Tab eventKey="reports" title="Reports">
            <div className="cars-add-btn-dv">
              <input name="s-date" id="s-date" type="date" />
              <span style={{ width: '30px' }}> </span>
              <input name="e-date" id="e-date" type="date" />
              <span style={{ width: '10px' }}> </span>
              <Button variant="contained" onClick={search}>
                SEARCH
              </Button>
            </div>
            <div style={{ marginLeft: '20px' }}>
              <p id="searchMessage">{searchMessage}</p>
            </div>
            {Object.keys(tDatas).length !== 0 && (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead style={{ background: 'black' }}>
                    <TableRow className="">
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>No</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Plate</h6>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <h6 style={{ color: '#fff' }}>Total</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>4.5% AC Group</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Parking</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Ration</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Jali Muhanga</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Mukamira</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Jali Rubavu</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Jali Karongi</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Jali Rusizi</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Jali Ngororero</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Jali Kabaya</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>ATP Mahoko</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>KVS</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>KIGALI</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>MUHANGA</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>NGORORERO</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>MUKAMIRA</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>RUBAVU</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>KARONGI 1</h6>
                      </TableCell>

                      <TableCell>
                        <h6 style={{ color: '#fff' }}>GUHANAGURA</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>GUTEGERA Abagenzi</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>PNEU</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Garage</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Garage(Big)</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Garage(Owner)</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Mazout</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Ikinamaba</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>SP</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>HASS</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>MEREZ</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>RESTE</h6>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {displayData.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell align="right">{row.i}</TableCell>
                        <TableCell align="right">{row.plate}</TableCell>
                        <TableCell align="right">
                          {new Intl.NumberFormat().format(row.amount)}
                        </TableCell>
                        <TableCell align="right">
                          {new Intl.NumberFormat().format(row.ac)}{' '}
                        </TableCell>
                        <TableCell align="right">
                          {new Intl.NumberFormat().format(row.kivu_belt)}
                        </TableCell>
                        <TableCell align="right">{row.ration}</TableCell>
                        <TableCell align="right">{row.jali_muhanga}</TableCell>
                        <TableCell align="right">{row.mukamira}</TableCell>
                        <TableCell align="right">{row.jali_rubavu}</TableCell>

                        <TableCell align="right">{row.jali_karongi}</TableCell>
                        <TableCell align="right">{row.jali_rusizi}</TableCell>
                        <TableCell align="right">
                          {row.jali_ngororero}
                        </TableCell>
                        <TableCell align="right">{row.jali_kabaya}</TableCell>
                        <TableCell align="right">{row.atp_mahoko}</TableCell>
                        <TableCell align="right">{row.kvcs}</TableCell>
                        <TableCell align="right">{row.kigali}</TableCell>
                        <TableCell align="right">{row.muhanga}</TableCell>
                        <TableCell align="right">{row.ngororero}</TableCell>
                        <TableCell align="right">{row.mukamirah}</TableCell>
                        <TableCell align="right">{row.rubavu}</TableCell>
                        <TableCell align="right">{row.karongi}</TableCell>
                        <TableCell align="right">{row.guhanagura}</TableCell>
                        <TableCell align="right">{row.gutega}</TableCell>
                        <TableCell align="right">{row.pneu}</TableCell>
                        <TableCell align="right">{row.sgarage}</TableCell>
                        <TableCell align="right">{row.garage}</TableCell>
                        <TableCell align="right">{row.ogarage}</TableCell>
                        <TableCell align="right">{row.mazout}</TableCell>
                        <TableCell align="right">{row.ikinamba}</TableCell>
                        <TableCell align="right">{row.sp}</TableCell>
                        <TableCell align="right">{row.hashi}</TableCell>
                        <TableCell align="right">{row.merez}</TableCell>
                        <TableCell align="right">
                          {new Intl.NumberFormat().format(row.rest)}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell align="right">Total</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(total)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(ac5)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(kivu_belt_to)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(ration)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(jali_muhanga)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(mukamira)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(jali_rubavu)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(jali_karongi)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(jali_rusizi)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(jali_ngororero)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(jali_kabaya)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(atp_mahoko)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(kvcs)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(kigali)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(muhanga)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(ngororero)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(mukamirah)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(rubavu)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(karongi)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(guhanagura)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(gutega)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(pneu)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(sgarage)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(garage)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(ogarage)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(mazout)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(ikinamba)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(sp)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(hashi)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(merez)}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat().format(restTotal)}
                      </TableCell>
                    </TableRow>

                    {Object.keys(tDatas).length === 0 && <p>Loading........</p>}
                  </TableBody>
                </Table>
                <Button
                  variant="outlined"
                  onClick={() => exportData(data)}
                  startIcon={<GetAppIcon />}
                >
                  Export data
                </Button>
              </TableContainer>
            )}
          </Tab>
          {/* <Tab eventKey="dration" title="Ration(single drivers)">
            <SingleDriverRation />
          </Tab>
          <Tab eventKey="drsration" title="Ration(Many drivers)">
            <DriversRation />
          </Tab> */}
        </Tabs>
        {/* Tabs ends here */}
      </div>
    </div>
  );
}
